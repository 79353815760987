<template>
  <div>
    <div class="hxcp"></div>
    <!-- 内容一 -->
    <div class="content1 container">
      <show :styles="{ top: '7em', left: '-20em' }" :moveData="{ left: '0em' }">
        <img src="../../assets/images/bg_img/LinkEdge.jpg" alt="" />
      </show>
      <show :styles="{ top: '11em', left: '70em' }" :moveData="{ left: '45em' }">
        <div class="infos">
          <p class="title1">边缘计算产品</p>
          <p class="title2">LinkEdge</p>
          <p class="border"></p>
          <p class="text">
            根据不同应用场景，提供包括智能物联网关、一体化智能采集柜、自供电微
          </p>
          <p class="text">
            功耗RTU、工业级4G DTU等边缘计算产品，实现物联网设备和数据的
          </p>
          <p class="text">简单、快速接入和采集。</p>
        </div>
      </show>
      <show :styles="{ bottom: '2em', left: '50em' }" :moveData="{ left: '35em' }">
        <p class="common_title title_sty">产品介绍</p>
      </show>
    </div>
    <!-- 内容二 -->
    <div class="content2">
      <div class="container">
        <show :styles="{ top: '7em', left: '-0em' }" :moveData="{ left: '12em' }">
          <p class="title">一体化智能采集柜</p>
          <p class="subTitle">Integrated intelligent acquisition cabinet</p>
          <p class="text">适用于需现场数据监看和有市电接入的采集场景</p>
          <p class="text">一体化设计，易于安装和维护</p>
          <p class="text">7寸液晶显示屏，现场直观查看设备运行状态</p>
          <p class="text">支持模拟量、数字量、视频流采集</p>
          <p class="text">支持国内外主流PLC协议和仪器仪表协议</p>
          <p class="text">支持断点续传，确保数安全</p>
          <p class="text">支持无线（2G/3G/4G）、有线、Wifi多种通讯方式</p>
        </show>
        <show :styles="{ top: '8em', left: '60em' }" :moveData="{ left: '45em' }">
          <img src="../../assets/images/bg_img/LinkEdge2.png" alt="" />
        </show>

        <show :styles="{ top: '38em', left: '-10em' }" :moveData="{ left: '12em' }">
          <img src="../../assets/images/bg_img/LinkEdge3.png" alt="" />
        </show>

        <show :styles="{ top: '36em', left: '60em ' }" :moveData="{ left: '45em' }">
          <p class="title">工业级智能网关</p>
          <p class="subTitle">Industrial intelligent gateway</p>
          <p class="text">工业级设计，适用于水厂、泵房等点位较多的场景</p>
          <p class="text">支持国内外主PLC、ModbusRTU/TCP通讯协议及非标协议</p>
          <p class="text">支持2G/3G/4G、以太网等网络接入方式</p>
          <p class="text">提供强大的组态及仿真工具</p>
          <p class="text">支持边缘计算，有效分担云端计算资源</p>
          <p class="text">支持网络自恢复、远程管理、健康自诊断等功能</p>
        </show>
      </div>
    </div>
    <!-- 内容三 -->
    <div class="content3 container">
      <show :styles="{ top: '7em', left: '-20em' }" :moveData="{ left: '12em' }">
        <p class="title">自供电微功耗RTU</p>
        <p class="subTitle">Self powered micro power RTU</p>
        <p class="text">适用于无市电接入条件的数据采集场景</p>
        <p class="text">防水等级：IP68</p>
        <p class="text">可以长期浸泡在水中工作</p>
        <p class="text">密封盖采用硅胶圈防水，抗腐蚀</p>
        <p class="text">传感器接口及天线接口采用防水连接器</p>
        <p class="text">内置存储器，可以存储3万多点数据</p>
        <p class="text">内置电池，在15分钟采集一次，2个小时上报一次的工作模式下可以</p>
        <p class="text">保证终端持续工作4年</p>
      </show>
      <show :styles="{ top: '9em', left: '60em' }" :moveData="{ left: '45em' }">
        <img src="../../assets/images/bg_img/LinkEdge4.png" alt="" />
      </show>

      <show :styles="{ top: '35em', left: '-20em' }" :moveData="{ left: '12em' }">
        <img style="width:24.125em" src="../../assets/images/bg_img/LinkEdge5.png" alt="" />
      </show>

      <show :styles="{ top: '36em', left: '60em ' }" :moveData="{ left: '45em' }">
        <p class="title">工业级4G DTU</p>
        <p class="subTitle">Industrial 4G DTU</p>
        <p class="text">工业级4G DTU，适用于各种需要集成的场景</p>
        <p class="text">1路串口（RS485/RS232）</p>
        <p class="text">2 路 DI（干/湿节点）</p>
        <p class="text">1 路模拟量（电流）</p>
        <p class="text">支持本地逻辑处理运算</p>
        <p class="text">自动采集主动上报</p>
        <p class="text">Modbus RTU/TCP自适应、自动校准阈值上报</p>
        <p class="text">组态软件对接</p>
        <p class="text">干湿节点IO检测</p>
        <p class="text">支持一对一、多对一组网</p>
      </show>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.content1 {
  height: 40.5625em;

  img {
    width: 37.5em;
  }
}
.content2,
.content3 {
  height: 60em;
  background: url("../../assets/images/bg_img/product_bg.jpg") no-repeat center
    transparent;
  color: #fff;
  .title {
    font-size: 1.9em;
    font-weight: bold;
  }
  .subTitle {
    margin-top: 0.5em;
    margin-bottom: 1.5em;
  }
  .subTitle,
  .text {
    font-size: 0.9em;
    color: #dedede;
  }
  .text {
    width: 30em;
    margin-bottom: 0.4em;
  }
}
.content3 {
  background: none;
  .title {
    color: #1aa0f8;
  }
  .subTitle,
  .text {
    color: #666666;
  }
}
</style>
